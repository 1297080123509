import { get, set, toPairs } from 'lodash';
import { getScript } from 'ct-lib/get-script';

import { initStore } from './init';
import { logError, setStream, logInfo } from './logging';
import { fetchData } from './fetch';

/* global ENV_URLS ENV_DEV ENV_INTEGRATION */
const URLS = ENV_URLS;
const POLYFILL_IO_URL =
  '//polyfill-fastly.net/v3/polyfill.min.js?features=default,es6,Array.prototype.includes,Object.entries,Object.values,Element.prototype.dataset,String.prototype.includes,Map,fetch';

const LOAD_APP_TRIES = 2;
const SCRIPT_TIMEOUT_MS = 3000;

function getEnvironment() {
  const currentScript = document.currentScript.src;
  if (window.location.hostname.includes('smartblock.internal.cartrawler.com')) {
    console.log('Environment: pr environment');
    return 'pr-environment';
  }
  if (window.location.hostname === 'smartblock') {
    console.log('Environment: local');
    return 'local';
  }
  if (currentScript.includes('ajaxgeo.cartrawler.com')) {
    console.log('Environment: Production');
    return 'production';
  }
  console.log('Environment: External Dev');
  return 'external-dev';
}

function checkVersionToLoad(configName) {
  const environmentName = getEnvironment();
  const sb4PartnerListurl =
    environmentName === 'production'
      ? 'https://ajaxgeo.cartrawler.com/smartblock/sb4/configs/partner-list.json'
      : 'https://external-dev-ajax.cartrawler.com/smartblock/sb4/configs/partner-list.json';

  return fetchDataAsync(sb4PartnerListurl, SCRIPT_TIMEOUT_MS)
    .then(data => {
      const partners = JSON.parse(data);
      const partner = partners.find(p => p.partner === configName);

      if (partner) {
        const environment =
          environmentName === 'production' ? 'production' : 'external-dev';
        const migration = partner.versions[environment].migration;

        if (migration.forceV4) {
          return { version: 4, percentage: migration.percentage, environment };
        }
        return { version: 3 };
      }
      return { version: 3 };
    })
    .catch(error => {
      logError('LOADING_PARTNER_LIST_ERROR', error);
      return { version: 3 };
    });
}

function fetchDataAsync(url, timeout) {
  return new Promise((resolve, reject) => {
    fetchData(url, resolve, reject, timeout);
  });
}

function handleMigration(versionToLoad, percentage, environment) {
  const forcedVersion = sessionStorage.getItem('ct_forced_version');

  if (forcedVersion) {
    if (forcedVersion === 'v4' || forcedVersion === 'sb4') {
      loadSB4Loader(environment);
    } else {
      versionToLoad = 3;
    }
  } else {
    const randomPercentage = Math.floor(Math.random() * 100);
    if (randomPercentage < percentage) {
      sessionStorage.setItem('ct_forced_version', 'sb4');
      loadSB4Loader(environment);
    } else {
      sessionStorage.setItem('ct_forced_version', 'v3');
      versionToLoad = 3;
    }
  }
}

function loadSB4Loader(environment) {
  const loaderURL =
    environment === 'production'
      ? 'https://ajaxgeo.cartrawler.com/smartblock/sb4/loader.js'
      : 'https://external-dev-ajax.cartrawler.com/smartblock/sb4/loader.js';

  getScript(
    loaderURL,
    () => {},
    error => {
      logError('LOADING_PARTNER_LOADER_ERROR', error);
    },
    SCRIPT_TIMEOUT_MS
  );
}

function initializeSmartblockV3(smartblock, configName) {
  initStore();

  const isStorageAvailable = (() => {
    try {
      sessionStorage.setItem('ct.smartblock.test', 'test');
      sessionStorage.removeItem('ct.smartblock.test');
      localStorage.setItem('ct.smartblock.test', 'test');
      localStorage.removeItem('ct.smartblock.test');
      return true;
    } catch (e) {
      logInfo('STORAGE_NOT_AVAILABLE', e);
      return false;
    }
  })();

  let polyfillRequested = false; //can't use promises since polyfill hasn't been loaded yet
  const onPolyfillLoaded = () => {
    polyfillRequested = true;
    loadApp();
  };
  const onPolyfillError = error => {
    logError('LOADING_POLYFILL_ERROR', error);
    polyfillRequested = true;
    loadApp();
  };

  let versionRequested = false; //can't use promises since polyfill hasn't been loaded yet
  const onVersionLoaded = () => {
    versionRequested = true;
    loadApp();
  };
  const onVersionError = error => {
    logError('CTLOADER_SERVICE_ERROR', error);
    versionRequested = true;
    loadApp();
  };

  let clientConfig = {};
  let configRequested = false;
  const onConfigLoad = data => {
    try {
      clientConfig = JSON.parse(data);
      if (clientConfig) {
        if (!get(window.CTStore, 'dataSource.clientID')) {
          set(
            window.CTStore,
            'dataSource.clientID',
            get(clientConfig, 'clientId')
          );
        }
        set(window.CTStore, 'config.generic.clientConfig', clientConfig);
      } else {
        logError(
          'CONFIG_NOT_FOUND',
          `config not found for client ${configName}`
        );
      }
    } catch (error) {
      logError('LOADING_CONFIG_READ_ERROR', error);
    }
    configRequested = true;
    if (ENV_DEV) {
      loadApp();
    }
    if (!ENV_DEV) {
      fetchVersions(get(window.CTStore, 'dataSource.clientID'));
    }
  };
  const onConfigFail = error => {
    logError('LOADING_CONFIG_ERROR', error);
    configRequested = true;
    if (ENV_DEV) {
      loadApp();
    }
    if (!ENV_DEV) {
      fetchVersions();
    }
  };

  let appTries = 0;
  const onAppLoaded = () => {};
  const onAppError = error => {
    if (appTries < LOAD_APP_TRIES) {
      appTries++;
      logError(`LOADING_APP_RETRY_${appTries}`);
      loadAppScript();
    } else {
      logError('LOADING_APP_FAIL', error);
    }
  };
  const loadAppScript = () => {
    getScript(
      `${get(window.CTStore, 'config.appURL')}app.generic.js`,
      onAppLoaded,
      onAppError,
      SCRIPT_TIMEOUT_MS
    );
  };
  const loadApp = () => {
    try {
      if (versionRequested && polyfillRequested && configRequested) {
        if (!ENV_DEV) {
          let isMVTEnabled = false;
          let mvtFlag = '';

          const forceMVTVersion = sessionStorage.getItem('ctForceMVTVersion');
          if (forceMVTVersion) {
            set(window.CTStore, 'config.generic.mvtFlag', forceMVTVersion);
          }

          // Check for MVT flag in the global variable
          if (window.CTStore.config.generic.mvtFlag) {
            isMVTEnabled = true;
            mvtFlag = window.CTStore.config.generic.mvtFlag;
          }
          if (
            !mvtFlag &&
            window.CTStore &&
            window.CTStore.dataSource &&
            window.CTStore.dataSource.MVTVersion
          ) {
            isMVTEnabled = true;
            mvtFlag = window.CTStore.dataSource.MVTVersion;
          }

          // Check for MVT flag in CTMVTBucket if not set by global variable
          if (!mvtFlag && window.CTMVTBucket) {
            isMVTEnabled = true;
            if (window.CTMVTBucket === 'ABE.A') {
              mvtFlag = '1';
            } else if (window.CTMVTBucket === 'ABE.B') {
              mvtFlag = '2';
            } else if (window.CTMVTBucket === 'ABE.C') {
              mvtFlag = '3';
            }
          }

          // Set the MVT flag in CTStore
          if (mvtFlag) {
            set(window.CTStore, 'config.generic.mvtFlag', mvtFlag);
          }

          // Check for MVT flag in session storage if not set previously
          if (isStorageAvailable) {
            const forceMVTFlag = sessionStorage.getItem(
              'ct.smartblock.mvtFlag'
            );
            if (forceMVTFlag) {
              set(window.CTStore, 'config.generic.mvtFlag', forceMVTFlag);
            }
          }

          const appVersion =
            (!isMVTEnabled && window.CTABVersion) ||
            get(clientConfig, 'defaultVersion.app');
          const clientVersion =
            (!isMVTEnabled && window.CTABMinVersion) ||
            get(clientConfig, 'defaultVersion.config');

          if (appVersion) {
            set(
              window.CTStore,
              'config.appURL',
              `${URLS.AJAX}/smartblock/${appVersion}/`
            );
            set(window.CTStore, 'config.OTA.version', appVersion);
          }
          if (clientVersion) {
            set(window.CTStore, 'config.generic.configVersion', clientVersion);
          }
          if (appVersion && clientVersion) {
            loadAppScript();
          } else {
            logError(
              'RESOLVE_VERSION_FAIL',
              `could not resolve version for app or client: app=${
                appVersion || 'undefined'
              }, client=${clientVersion || 'undefined'}`
            );
          }
        }

        set(
          window.CTStore,
          'config.baseURL',
          get(window.CTStore, 'config.appURL')
        );
        if (ENV_DEV) {
          loadAppScript();
        }
      }
    } catch (error) {
      logError('LOADING_APP_JS_ERROR', error);
    }
  };

  const fetchVersions = clientId => {
    const params = {
      action: 'version',
      engine: 'SB2.0',
      stream: configName || 'generic'
    };
    if (clientId) {
      params.clientId = clientId;
    }
    const ctLoaderParams = toPairs(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    getScript(
      `${ENV_URLS.AB_SERVER}?${ctLoaderParams}`,
      onVersionLoaded,
      onVersionError,
      SCRIPT_TIMEOUT_MS
    );
  };

  if (smartblock) {
    set(window.CTStore, 'config.OTA.clientName', configName);
    set(window.CTStore, 'config.generic.configName', configName);

    let clientConfigPath;
    if (!ENV_DEV) {
      set(
        window.CTStore,
        'config.generic.configPath',
        `${URLS.AJAX}/smartblock/config/`
      );
      clientConfigPath = `${URLS.AJAX}/smartblock/config/`;
    }
    if (ENV_DEV) {
      const rootDistDir = ENV_INTEGRATION ? '//smartblock:8080/' : '/';
      set(window.CTStore, 'config.appURL', `${rootDistDir}dist/`);
      set(
        window.CTStore,
        'config.generic.configPath',
        `${rootDistDir}configs/`
      );
      versionRequested = true;
      clientConfigPath = `${rootDistDir}configs/`;
    }
    getScript(
      POLYFILL_IO_URL,
      onPolyfillLoaded,
      onPolyfillError,
      SCRIPT_TIMEOUT_MS
    );
    console.log(
      'clientConfigPath',
      `${clientConfigPath}${configName}/config.json`
    );
    fetchData(
      `${clientConfigPath}${configName}/config.json`,
      onConfigLoad,
      onConfigFail,
      SCRIPT_TIMEOUT_MS
    );
  }
}

try {
  const smartblock = document.querySelector('ct-smartblock');
  let configName;
  if (smartblock) {
    configName = smartblock.getAttribute('data-config-name');
    setStream(configName || 'generic');
    if (!configName) {
      configName = 'generic';
      logError(
        'MISSING_DATA_CONFIG_NAME',
        'data-config-name attribute not set in <ct-smartblock> element, SB loading with generic config'
      );
    }
  } else {
    logError(
      'MISSING_SMARTBLOCK_DOM_ELEMENT',
      'missing <ct-smartblock> element'
    );
  }
  loadApp(configName, getEnvironment(), smartblock);
} catch (error) {
  logError('UNKNOWN_SCRIPT_ERROR', error);
}

function loadApp(configName, environment, smartblock) {
  console.log('configName', configName, environment, smartblock);
  checkVersionToLoad(configName).then(version => {
    if (version.version === 4) {
      handleMigration(version.version, version.percentage, environment);
    }
    initializeSmartblockV3(smartblock, configName);
  });
}
